import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { graphql } from "gatsby";

import Layout from "../components/containers/layout";
import Heading from "../components/typography/heading";
import Container from "../components/containers/container";
import Section from "../components/containers/section";
import SEO from "../components/globals/seo";

const PolicyWrap = styled(Container)`
  p {
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  a {
    font-family: ${p => p.theme.headingFont};
    transition: ${p => p.theme.transitionDefault};

    &:hover {
      color: ${p => p.theme.green};
    }
  }

  strong, bold {
    font-size: ${p => p.theme.baseFontSizeTabMob};

    @media (min-width: ${p => p.theme.screenL}) {
      font-size: ${p => p.theme.baseFontSizeDesktop};
    }
  }
`;

const PrivacyPolicyPage = ({ data }) => {
  const { title, content } = data.prismic.data;

  return (
    <Layout>
      <SEO title="Privacy Policy" description="The Privacy Policy for TopTekkers" />
      <Section>
        <PolicyWrap>
          <Heading>{title.text}</Heading>
          {/* eslint-disable-next-line react/no-danger */}
          <div dangerouslySetInnerHTML={{ __html: content.html }} />
        </PolicyWrap>
      </Section>
    </Layout>
  );
};

export const query = graphql`
{
  prismic: prismicPrivacyPolicy {
    data {
      title {
        text
      }
      content {
        html
      }
    }
  }
}
`;

PrivacyPolicyPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default withTheme(PrivacyPolicyPage);
